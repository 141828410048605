import React,{ useState } from "react";
import { Col, Row, Dropdown,Menu,Modal,Form,DatePicker,Select, Button,Card } from "antd";
import {getComboFillForDashboard } from "../../services/generic";
import { genericUrl } from "../../constants/serverConfig";
import FilterIcon from "../../assets/images/filterIcon.svg";
import settingIcon from "../../assets/images/settingsIcon.svg";
import refreshIcon from "../../assets/images/refreshIcon.svg";
import moment from 'moment'
import Axios from "axios";


const{Option}=Select
const dateFormat = "YYYY/MM/DD";

const HeaderComponent = (props) => {
  // console.log("====props====",props)
  const {dashboardFilters,isComparableFlag,kpiData,dashboardId,filteredData,currentYearDateChange,previousYearDateChange,clearFilterValues}=props
  const [filterPopup, setFilterPopup] = useState(false);
  const [dropdownDetails, setDropdownDetails] = useState([]);
  const [referenceListDetails, setListReferenceDetails] = useState([]);
  const [clickedFiltersCount, setClickedFiltersCount] = useState(0)
  const [form] = Form.useForm();
  const [comparableForm] = Form.useForm();

  /* useEffect(() => {
    // console.log("========>Inside Dashboard<=========")
    if (dashboardId) {
      getDashboardData();
    }
    return () => {
      form.resetFields();
    };
  }, []); */

  

  const filterMode = () => {
    // console.log("===filtermode==",dashboardFilters)
    setClickedFiltersCount(clickedFiltersCount + 1)
    const currentDateArray = []
    if(clickedFiltersCount===0){
      for (let index = 0; index < dashboardFilters.length; index += 1) {
        const element = dashboardFilters[index]
        if (element.type === 'DateRange') {
          const columnName = element.column_name
          const defaultValue = element.default_value_expression.split(';')
          // this.setState({ dateFilterArray: defaultValue })
          const fromDate = new Date(defaultValue[0])
          const toDate = new Date(defaultValue[1])
          const formattedFromDate = fromDate.getFullYear() - 1 + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate()
          const formattedToDate = toDate.getFullYear() - 1 + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate()
  
          currentDateArray.push(formattedFromDate, formattedToDate)
          // console.log('======>currentDateArray<========', currentDateArray)
          form.setFieldsValue({
            fromDate: moment(defaultValue[0], dateFormat),
            toDate: moment(defaultValue[1], dateFormat),
            // moment(defaultValue[1], dateFormat),
          })
  
          if (isComparableFlag === 'Y') {
            comparableForm.setFieldsValue({
              comparableFromDate: moment(currentDateArray[0], dateFormat),
              comparableToDate: moment(currentDateArray[1], dateFormat),
            })
          }
        }
      }
    }
    
    setFilterPopup(true)
  };

  const submitFilterDetails=()=>{
    form.submit();
    // setFilterPopup(false)
  }

  const onFinish = async (values) => {
    const comparableFormValues = comparableForm.getFieldsValue(true)
    // console.log("===comparable form values===",comparableFormValues)
    const currentYearFromDate = values.fromDate!==undefined?(values.fromDate).format("YYYY-MM-DD"):''
    const currentYearToDate = values.toDate!==undefined?(values.toDate).format("YYYY-MM-DD"):''
    const comparableFromDate = comparableFormValues.comparableFromDate!==undefined?(comparableFormValues.comparableFromDate).format("YYYY-MM-DD"):''
    const comparableToDate = comparableFormValues.comparableToDate!==undefined?(comparableFormValues.comparableToDate).format("YYYY-MM-DD"):''
    const currentYearDatesArray = []
    const previousYearArray = [];
    let comparableValue;
    currentYearDatesArray.push(currentYearFromDate,currentYearToDate)

    if (dashboardFilters !== undefined) {
      for (let index = 0; index < dashboardFilters.length; index += 1) {
        const element = dashboardFilters[index];
        if (dashboardFilters[index].type === "DateRange") {
          comparableValue = element.column_name
          values[element.column_name] = currentYearDatesArray

        }
      }
      if (isComparableFlag === "Y") {
        const fromDate = new Date(comparableFromDate);
        const toDate = new Date(comparableToDate);
        const formattedFromDate = fromDate.getFullYear() + "-" + (fromDate.getMonth() + 1) + "-" + fromDate.getDate();
        const formattedToDate = toDate.getFullYear() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate();
        previousYearArray.push(formattedFromDate, formattedToDate);
        values[comparableValue.concat("_COMPARABLE_")] = previousYearArray;
      }

      for (const [key, value] of Object.entries(values)) {
        if (value === undefined) {
          delete values[key];
        }
        delete values["fromDate"]
        delete values["toDate"]
      }


      props.paramsValue(values)
      const stringifiedJSON = JSON.stringify(values);
      const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const headers = { "Content-Type": "application/json",Authorization: `bearer ${newToken.access_token}`};
      for (let index = 0; index < kpiData.length; index++) {
        const element = kpiData[index];
        props.loadingAfterFiltersApplied('Y',element.kpi_id)
        // setKpiLoading()
        const executeDashboardMutation = {
          query: `query {
          executeDashboard(dashboardId:"${dashboardId}",kpiId:"${element.kpi_id}",dashboardParam:"${jsonToSend}"){data, messageCode, title, message}
        }`,
        };
        Axios.post(genericUrl, executeDashboardMutation, { headers: headers }, { async: true }, { crossDomain: true }).then((execDashRes) => {
          const responseFromServer = execDashRes.data.data.executeDashboard;
          if (responseFromServer.title === "Success") {
            const dashboardData = JSON.parse(responseFromServer.data);
            // console.log("====dashboardData====",dashboardData)
            // props.apply(dashboardData)
            props.loadingAfterFiltersApplied('N',element.kpi_id)
            filteredData(dashboardData)
            setFilterPopup(false)
            // dashBoardArr.push(dashboardData)
          } else {
            console.log("======Error Data=====", responseFromServer.message);
          }
        });
      }
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onDropDownSelect = async (id) => {
    const getDashboardData = await getComboFillForDashboard(id);
    setDropdownDetails([...getDashboardData]);
  };


  const onDropDownSelectForListReference = (values) => {
    // console.log("======>values<=====", values);

    setListReferenceDetails([...values]);
  };


  const fullScreenMode = () => {
    props.fullScreen(true);
  };

  const nightMode = () => {
    console.log("night");
  };

  const refreshDashboard = () => {
    // console.log("=====refresh Dashboard========",props)
    props.refreshDashboard()
  };


  const onCurrentFromDateChange = (date, dateString) => {
    // const { form, isComparableFlag } = this.props
    const fromDateValue = new Date(dateString)
    const fromDateYear = fromDateValue.getFullYear()
    const formattedFromDateToSet = fromDateValue.getFullYear() - 1 + '-' + (fromDateValue.getMonth() + 1) + '-' + fromDateValue.getDate()
    // console.log("formattedFromDateToSet",formattedFromDateToSet)
    if (isComparableFlag === 'Y') {
      comparableForm.setFieldsValue({
        comparableFromDate: moment(formattedFromDateToSet, dateFormat),
      })
    }
    currentYearDateChange({
      isDateChanged: true,
      currentYearFlag: true,
      fromDateYearInState: fromDateYear,
    })
    /* this.setState({
      isDateChanged: true,
      currentYearFlag: true,
      fromDateYearInState: fromDateYear,
    }) */
  }

  const onCurrentToDateChange = (date, dateString) => {
    // const { form, isComparableFlag } = this.props
    const toDateValue = new Date(dateString)
    const toDateYear = toDateValue.getFullYear()
    const formattedToDateToSet = toDateValue.getFullYear() - 1 + '-' + (toDateValue.getMonth() + 1) + '-' + toDateValue.getDate()

    if (isComparableFlag === 'Y') {
      comparableForm.setFieldsValue({
        comparableToDate: moment(formattedToDateToSet, dateFormat),
      })
    }
    currentYearDateChange({
      isDateChanged: true,
      currentYearFlag: true,
      fromDateYearInState: toDateYear,
    })
    /* this.setState({
      isDateChanged: true,
      currentYearFlag: true,
      fromDateYearInState: toDateYear,
    }) */
  }

  const onComparableFromDateChange = (date, dateString) => {  
    const fromDateValue = new Date(dateString)
    const fromDateYear = fromDateValue.getFullYear()
    // console.log("========fromDateYear=======",fromDateYear)
    previousYearDateChange({
      isDateChanged: true,
      previousYearFlag: true,
      fromDateYearInState: fromDateYear,
    })
    /* this.setState({
      isDateChanged: true,
      previousYearFlag: true,
      fromDateYearInState: fromDateYear,
    }) */
  }

  const clearValues=()=>{
    setFilterPopup(false)
  }

  const closeFilterModal = () => {
    clearFilterValues()
    setClickedFiltersCount(0)
    setFilterPopup(false)
    form.resetFields();
  };
  


  /* const onComparableToDateChange = (date, dateString) => {
    const toDateValue = new Date(dateString)
    const fromDateYear = toDateValue.getFullYear()

    this.props.previousYearDateChange({
      isDateChanged: true,
      previousYearFlag: true,
      fromDateYearInState: fromDateYear,
    })

    this.setState({
      isDateChanged: true,
      previousYearFlag: true,
      fromDateYearInState: fromDateYear,
    })
  } */

  const settingIconMenu = (
    <Menu>
      <Menu.Item key="1" onClick={fullScreenMode}>
        Expand
      </Menu.Item>
    </Menu>
  );

  const responsiveDesignForColumn = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 12,
    sm: 12,
    md: 12,
  };

  return (
    <div>
      <Row>
        <Col {...responsiveDesignForColumn}>
          <div style={{ fontSize: "20px", fontWeight: "bold" }}>{props.dashboardTitle}</div>
        </Col>
        <Col {...responsiveDesignForColumn}>
          <span style={{ float: "right" }}>
            <span>
              {/* <FilterOutlined style={{ cursor: "pointer", fontSize: "18px" }} onClick={filterMode} /> */}
              <img alt="filter" src={FilterIcon} onClick={filterMode} style={{ cursor: "pointer" }} />
            </span>
            &emsp;
            <span>
              <Dropdown overlay={settingIconMenu} placement="bottomRight">
                <img alt="setting" src={settingIcon} style={{ cursor: "pointer" }} />
              </Dropdown>
            </span>
            &emsp;
            <span>
              <img alt="refresh" src={refreshIcon} onClick={refreshDashboard} style={{ cursor: "pointer" }} />
              {/* <ExpandAltOutlined style={{ cursor: "pointer", fontSize: "18px", marginBottom: "10px" }} onClick={fullScreenMode} /> */}
            </span>
            &emsp;
          </span>
        </Col>
      </Row>
      <Modal
        title="Manage Filters"
        visible={filterPopup}
        width="400px"
        // onOk={submitFilterDetails}
        footer={[
          <Button type="default" onClick={closeFilterModal}>
            Clear
          </Button>,
          <Button type="default" onClick={clearValues}>
            Cancel
          </Button>,
          <Button type="primary" onClick={submitFilterDetails}>
            Apply
          </Button>,
        ]}
        onCancel={closeFilterModal}
        centered
      >
        <div style={{padding:"8px"}}>
          <Row>
            {dashboardFilters !== undefined
              ? dashboardFilters.map((dashboardFilters, index) => {
                  return (
                    <Col span={24} key={index} style={{ display: dashboardFilters.is_for_prompting === "Y" ? "block" : "none" }}>
                      <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                        <Form.Item
                          label={dashboardFilters.type === "DateRange" ? null : dashboardFilters.display_name}
                          name={dashboardFilters.type === "DateRange" ? null : dashboardFilters.column_name}
                          rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                        >
                          {dashboardFilters.type === "MultiSelector" ? (
                            <Select
                              style={{ width: "100%" }}
                              // onSearch={searchDropdownRecords}
                              mode="multiple"
                              maxTagCount={1}
                              showSearch
                              allowClear
                              // notFoundContent={fetching ? <Spin size="small" /> : null}
                              dropdownMatchSelectWidth={false}
                              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              onFocus={() => onDropDownSelect(dashboardFilters.filter_id)}
                            >
                              {/* {dropdownList} */}
                              {dropdownDetails === null || dropdownDetails === undefined
                                ? null
                                : dropdownDetails.map((data) => {
                                    // console.log("===>data<====", data);
                                    return (
                                      <Option key={data.recordid} value={data.recordid}>
                                        {data.name}
                                      </Option>
                                    );
                                  })}
                            </Select>
                          ) : dashboardFilters.type === "DateRange" ? (
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="From Date"
                                  name="fromDate"
                                  rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                                >
                                  <DatePicker style={{ width: "100%" }} format={dateFormat} onChange={onCurrentFromDateChange} />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label="To Date"
                                  name="toDate"
                                  rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                                >
                                  <DatePicker style={{ width: "100%" }} format={dateFormat} onChange={onCurrentToDateChange} />
                                </Form.Item>
                              </Col>
                            </Row>
                          ) : dashboardFilters.type === "List" ? (
                            <Select
                              showSearch
                              allowClear
                              // notFoundContent={fetching ? <Spin size="small" /> : null}
                              dropdownMatchSelectWidth={false}
                              style={{ width: "100%" }}
                              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              onFocus={() => onDropDownSelectForListReference(dashboardFilters.Values)}
                            >
                              {referenceListDetails === null || referenceListDetails === undefined
                                ? null
                                : referenceListDetails.map((data) => {
                                    // console.log("===>data<====", data);
                                    return (
                                      <Option key={data.key} title={data.key} value={data.key}>
                                        {data.value}
                                      </Option>
                                    );
                                  })}
                            </Select>
                          ) : dashboardFilters.type === "Selector" ? (
                            <Select
                              showSearch
                              allowClear
                              // notFoundContent={fetching ? <Spin size="small" /> : null}
                              dropdownMatchSelectWidth={false}
                              style={{ width: "100%" }}
                              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              onFocus={() => onDropDownSelect(dashboardFilters.id)}
                            >
                              {dropdownDetails === null || dropdownDetails === undefined
                                ? null
                                : dropdownDetails.map((data) => {
                                    // console.log("===>data<====", data);
                                    return (
                                      <Option key={data.recordid} value={data.recordid}>
                                        {data.name}
                                      </Option>
                                    );
                                  })}
                            </Select>
                          ) : dashboardFilters.type === "Date" ? (
                            <DatePicker style={{ width: "100%" }} format={dateFormat} />
                          ) : (
                            ""
                          )}
                        </Form.Item>
                      </Form>
                      <br />
                    </Col>
                  );
                })
              : ""}
          </Row>
          {isComparableFlag === "Y" ? (
            <Form form={comparableForm} layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Comparable From Date"
                    name="comparableFromDate"
                    rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                  >
                    <DatePicker style={{ width: "100%" }} format={dateFormat} onChange={onComparableFromDateChange} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Comparable To Date"
                    name="comparableToDate"
                    rules={[{ required: dashboardFilters.mandatory === "Y" ? true : false, message: `Please Enter ${dashboardFilters.display_name}` }]}
                  >
                    <DatePicker style={{ width: "100%" }} format={dateFormat} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            ""
          )}

          <br />
        </div>
      </Modal>
    </div>
  );
};

export default HeaderComponent;
