import React, { useState, useEffect } from "react";
import { Card, Tabs, Row, Col, Form, Select, Checkbox, Button, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useGlobalContext } from "../../../lib/storage";
import Axios from "axios";
import StockAdjustment from './stockadjustment'
import { serverUrl } from "../../../constants/serverConfig";
import "antd/dist/antd.css";
import "../../../styles/antd.css";

const { TabPane } = Tabs;
const { Option } = Select;

const ApplicationSetup = () => {
  const { globalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI;

  const [checkedValuePurchasePrintPdf, setCheckedValuePurchasePrintPdf] = useState(1);
  const [checkedValueCreateGRN, setCheckedValueCreateGRN] = useState(1);
  const [checkedValuePurchaseCreateInvoice, setCheckedValuePurchaseCreateInvoice] = useState(1);
  const [checkedValuePurchaseSendEmail, setCheckedValuePurchaseSendEmail] = useState(1);
  const [checkedValuePurchaseShowSummary, setCheckedValuePurchaseShowSummary] = useState(1);
  const [checkedValuePurchaseChangePrice, setCheckedValuePurchaseChangePrice] = useState(1);
  const [checkedValuePurchaseImport, setCheckedValuePurchaseImport] = useState(1);
  const [checkedValuePurchaseDownloadTemplate, setCheckedValuePurchaseDownloadTemplate] = useState(1);

  const [checkedValueEnableWithoutPO, setCheckedValueEnableWithoutPO] = useState(1);
  const [checkedValueGRPrintPdf, setCheckedValueGRPrintPdf] = useState(1);
  const [checkedValueGRCreateInvoice, setCheckedValueGRCreateInvoice] = useState(1);
  const [checkedValueGRSendEmail, setCheckedValueGRSendEmail] = useState(1);
  const [checkedValueGRShowSummary, setCheckedValueGRShowSummary] = useState(1);

  const [checkedValueGRChangePrice, setCheckedValueGRChangePrice] = useState(1);
  const [checkedValueGRImport, setCheckedValueGRImport] = useState(1);
  const [checkedValueGRDownloadTemplate, setCheckedValueGRDownloadTemplate] = useState(1);

  const [checkedValueGRFreeQty, setCheckedValueGRFreeQty] = useState(1)
  const [checkedValueGRMRP, setCheckedValueGRMRP] = useState(1)
  const [checkedValueGRSalePrice, setCheckedValueGRSalePrice] = useState(1)
  
  const [checkedValueGRBasePrice, setCheckedValueGRBasePrice] = useState(1)
  const [checkedValueGRActualPriceMRP, setCheckedValueGRActualPriceMRP] = useState(1)
  const [checkedValueGRUnitPrice, setCheckedValueGRUnitPrice] = useState(1)
  
  const [checkedValueGRNetUnitPrice, setCheckedValueGRNetUnitPrice] = useState(1)
  const [checkedValueGRMargin, setCheckedValueGRMargin] = useState(1)
  const [checkedValueGROldMargin, setCheckedValueGROldMargin] = useState(1) 

  const [checkedValueGRNetAmount, setCheckedValueGRNetAmount] = useState(1)
  const [checkedValueGRGrossAmount, setCheckedValueGRGrossAmount] = useState(1)
  const [checkedValueGRTotalDiscount, setCheckedValueGRTotalDiscount] = useState(1)

  const [checkedValueGRDiscountValue, seCheckedValueGRDiscountValue] = useState(1)
  const [checkedValueGRDiscountType, setCheckedValueGRDiscountType] = useState(1)


  const [stockAdjustmentValues,setStockAdjustmentValues]=useState({})

  const [loading, setLoading] = useState(false);

  const [mainForm] = Form.useForm();

  useEffect(() => {
    getAppSetupData();
  }, []);

  const getAppSetupData = async () => {
    try {
      setLoading(true);
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const getAppSetupMutation = {
        query: `query{
          getAppSetup(application:"PO")
          {
              appSetupId
              application
              configJson
          }
        }`,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };

      const serverResponse = await Axios.post(serverUrl, getAppSetupMutation, { headers: headers }, { async: true }, { crossDomain: true });
      const appSetupResponse = serverResponse.data.data.getAppSetup;
      // const appSetupData = JSON.parse(appSetupResponse.data);
      if (appSetupResponse.length > 0) {
        const appSetupRecord = appSetupResponse[0];
        const { configJson } = appSetupRecord;

        const poParsedConfigJSON = JSON.parse(configJson);
        const { basePriceCalc, createGRN, createInvoice, printPDF, sendEmail, showSummary } = poParsedConfigJSON;
        mainForm.setFieldsValue({ basePriceCalculation: basePriceCalc });
        if (createGRN === "Y") {
          setCheckedValueCreateGRN(1);
        } else {
          setCheckedValueCreateGRN(0);
        }
        if (createInvoice === "Y") {
          setCheckedValuePurchaseCreateInvoice(1);
        } else {
          setCheckedValuePurchaseCreateInvoice(0);
        }

        if (printPDF === "Y") {
          setCheckedValuePurchasePrintPdf(1);
        } else {
          setCheckedValuePurchasePrintPdf(0);
        }

        if (sendEmail === "Y") {
          setCheckedValuePurchaseSendEmail(1);
        } else {
          setCheckedValuePurchaseSendEmail(0);
        }

        if (showSummary === "Y") {
          setCheckedValuePurchaseShowSummary(1);
        } else {
          setCheckedValuePurchaseShowSummary(0);
        }
        setLoading(false);
      } else {
        message.error("No Data Available");
        setLoading(false);
      }
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  };

  const callBackTabs = async (value) => {
    try {
      setLoading(true);
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const getAppSetupMutation = {
        query: `query{
          getAppSetup(application:"${value}")
          {
              appSetupId
              application
              configJson
          }
        }`,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };

      const serverResponse = await Axios.post(serverUrl, getAppSetupMutation, { headers: headers }, { async: true }, { crossDomain: true });
      const appSetupResponse = serverResponse.data.data.getAppSetup;
      if (appSetupResponse.length > 0) {
        // const appSetupData = JSON.parse(appSetupResponse.data);
        const appSetupRecord = appSetupResponse[0];
        const { application, configJson } = appSetupRecord;

        if (application === "PO") {
          const poParsedConfigJSON = JSON.parse(configJson);
          const { basePriceCalc, createGRN, createInvoice, printPDF, sendEmail, showSummary, changePrice , importPO , downloadTemplate} = poParsedConfigJSON;
          mainForm.setFieldsValue({ basePriceCalculation: basePriceCalc });
          if (createGRN === "Y") {
            setCheckedValueCreateGRN(1);
          } else {
            setCheckedValueCreateGRN(0);
          }

          if (createInvoice === "Y") {
            setCheckedValuePurchaseCreateInvoice(1);
          } else {
            setCheckedValuePurchaseCreateInvoice(0);
          }

          if (printPDF === "Y") {
            setCheckedValuePurchasePrintPdf(1);
          } else {
            setCheckedValuePurchasePrintPdf(0);
          }

          if (sendEmail === "Y") {
            setCheckedValuePurchaseSendEmail(1);
          } else {
            setCheckedValuePurchaseSendEmail(0);
          }

          if (showSummary === "Y") {
            setCheckedValuePurchaseShowSummary(1);
          } else {
            setCheckedValuePurchaseShowSummary(0);
          }

          if (changePrice === "Y") {
            setCheckedValuePurchaseChangePrice(1);
          } else {
            setCheckedValuePurchaseChangePrice(0);
          }

          if (importPO === "Y") {
            setCheckedValuePurchaseImport(1);
          } else {
            setCheckedValuePurchaseImport(0);
          }

          if (downloadTemplate === "Y") {
            setCheckedValuePurchaseDownloadTemplate(1);
          } else {
            setCheckedValuePurchaseDownloadTemplate(0);
          }
        }
        if (application === "GRN") {
          const grnparsedConfigJSON = JSON.parse(configJson); 
          const { enableWithoutPO, createInvoice, printPDF, sendEmail, showSummary,basePriceCalc,changePrice,importGRN,downloadTemplate,freeQty,MRP,salePrice,basePrice,actualPriceGreaterMrp,unitPrice,netunitPrice,margin,oldmargin,netamount,grossamount,totaldiscount,discountvalue,discounttype} = grnparsedConfigJSON;
          mainForm.setFieldsValue({ basePriceCalculationGRN: basePriceCalc });
          if (enableWithoutPO === "Y") {
            setCheckedValueEnableWithoutPO(1);
          } else {
            setCheckedValueEnableWithoutPO(0);
          }

          if (createInvoice === "Y") {
            setCheckedValueGRCreateInvoice(1);
          } else {
            setCheckedValueGRCreateInvoice(0);
          }

          if (printPDF === "Y") {
            setCheckedValueGRPrintPdf(1);
          } else {
            setCheckedValueGRPrintPdf(0);
          }

          if (sendEmail === "Y") {
            setCheckedValueGRSendEmail(1);
          } else {
            setCheckedValueGRSendEmail(0);
          }

          if (showSummary === "Y") {
            setCheckedValueGRShowSummary(1);
          } else {
            setCheckedValueGRShowSummary(0);
          }

          if (changePrice === "Y") {
            setCheckedValueGRChangePrice(1);
          } else {
            setCheckedValueGRChangePrice(0);
          }

          if (importGRN === "Y") {
            setCheckedValueGRImport(1);
          } else {
            setCheckedValueGRImport(0);
          }

          if (downloadTemplate === "Y") {
            setCheckedValueGRDownloadTemplate(1);
          } else {
            setCheckedValueGRDownloadTemplate(0);
          }

          if (freeQty === "Y") {
            setCheckedValueGRFreeQty(1);
          } else {
            setCheckedValueGRFreeQty(0);
          }

          if (MRP === "Y") {
            setCheckedValueGRMRP(1);
          } else {
            setCheckedValueGRMRP(0);
          }

          if (salePrice === "Y") {
            setCheckedValueGRSalePrice(1);
          } else {
            setCheckedValueGRSalePrice(0);
          }

          if (basePrice === "Y") {
            setCheckedValueGRBasePrice(1);
          } else {
            setCheckedValueGRBasePrice(0);
          }

          if (actualPriceGreaterMrp === "Y") {
            setCheckedValueGRActualPriceMRP(1);
          } else {
            setCheckedValueGRActualPriceMRP(0);
          }

          if (unitPrice === "Y") {
               setCheckedValueGRUnitPrice(1)
          } else {
              setCheckedValueGRUnitPrice(0);
           }
            
           if (netunitPrice === "Y") {
            setCheckedValueGRNetUnitPrice(1)
          } else {
            setCheckedValueGRNetUnitPrice(0);
          }

          if (margin === "Y") {
            setCheckedValueGRMargin(1)
          } else {
            setCheckedValueGRMargin(0);
          }

          if (oldmargin === "Y") {
            setCheckedValueGROldMargin(1)
          } else {
            setCheckedValueGROldMargin(0);
          }

          if (netamount === "Y") {
            setCheckedValueGRNetAmount(1)
          } else {
            setCheckedValueGRNetAmount(0);
          }

          if (grossamount === "Y") {
            setCheckedValueGRGrossAmount(1)
          } else {
            setCheckedValueGRGrossAmount(0);
          }

          if (totaldiscount === "Y") {
            setCheckedValueGRTotalDiscount(1)
          } else {
            setCheckedValueGRTotalDiscount(0);
          }

          if (discountvalue === "Y") {
            seCheckedValueGRDiscountValue(1)
          } else {
            seCheckedValueGRDiscountValue(0);
          }

          if (discounttype=== "Y") {
            setCheckedValueGRDiscountType(1)
          } else {
            setCheckedValueGRDiscountType(0);
          }

        }
        if (application === "Stock Adjustment") {
          const stockAdjustmentParsedConfigJSON = JSON.parse(configJson);
          setStockAdjustmentValues(stockAdjustmentParsedConfigJSON)
          // const { showSummary,importSA,downloadTemplate,upcSearch,skuSearch,fullCount,cyclicCount,openingBalance} = stockAdjustmentParsedConfigJSON;
        }
        setLoading(false);
      }else{
        message.error("No data available")
        setLoading(false);
      }
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  };

  const saveData = () => {
    mainForm.submit(); 
    console.log("mainForm",mainForm)
  };

  const onFinish = async (values) => {
    console.log("===values===",values)
    try {
      setLoading(true);
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const appSetupArray = [];
      appSetupArray.push(
        `{
          application:"PO"
          configJson: "{\\"basePriceCalc\\":${
            values.basePriceCalculation === undefined || values.basePriceCalculation === null ? null : `\\"${values.basePriceCalculation}\\"`
          },\\"printPDF\\":${checkedValuePurchasePrintPdf === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"createGRN\\":${
          checkedValueCreateGRN === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`
        },\\"createInvoice\\":${checkedValuePurchaseCreateInvoice === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"sendEmail\\":${
          checkedValuePurchaseSendEmail === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`
        },\\"showSummary\\":${checkedValuePurchaseShowSummary === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"changePrice\\":${checkedValuePurchaseChangePrice === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"importPO\\":${checkedValuePurchaseImport === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"downloadTemplate\\":${checkedValuePurchaseDownloadTemplate === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`}}" 
        },
        {
          application:"GRN"
          configJson: "{\\"basePriceCalc\\":${
            values.basePriceCalculationGRN === undefined || values.basePriceCalculationGRN === null ? null : `\\"${values.basePriceCalculationGRN}\\"`
          },\\"enableWithoutPO\\":${checkedValueEnableWithoutPO === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"printPDF\\":${
          checkedValueGRPrintPdf === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`
        },\\"createInvoice\\":${checkedValueGRCreateInvoice === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"sendEmail\\":${
          checkedValueGRSendEmail === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`
        },\\"showSummary\\":${checkedValueGRShowSummary === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"changePrice\\":${checkedValueGRChangePrice === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"importGRN\\":${checkedValueGRImport === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"downloadTemplate\\":${checkedValueGRDownloadTemplate === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"freeQty\\":${checkedValueGRFreeQty === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"MRP\\":${checkedValueGRMRP === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"salePrice\\":${checkedValueGRSalePrice === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"basePrice\\":${checkedValueGRBasePrice === 1?`\\"${"Y"}\\"`:`\\"${"N"}\\"`},\\"actualPriceGreaterMrp\\":${checkedValueGRActualPriceMRP === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"unitPrice\\":${checkedValueGRUnitPrice === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"netunitPrice\\":${checkedValueGRNetUnitPrice === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"margin\\":${checkedValueGRMargin === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"oldmargin\\":${checkedValueGROldMargin === 1 ? `\\"${"Y"}\\"`:`\\"${"N"}\\"`},\\"netamount\\":${checkedValueGRNetAmount === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"grossamount\\":${checkedValueGRGrossAmount === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"totaldiscount\\":${checkedValueGRTotalDiscount=== 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"discountvalue\\":${checkedValueGRDiscountValue=== 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`},\\"discounttype\\":${checkedValueGRDiscountType === 1 ? `\\"${"Y"}\\"` : `\\"${"N"}\\"`}}" 
        }
        {
          application:"Stock Adjustment"
          configJson: "{\\"showSummary\\":${values.sashowsummary === undefined || values.sashowsummary === null ? null : values.sashowsummary===true?`\\"${"Y"}\\"`:`\\"${"N"}\\"`},\\"importSA\\":${values.saimport === undefined || values.saimport === null ? null : values.saimport===true?`\\"${"Y"}\\"`:`\\"${"N"}\\"`},\\"downloadTemplate\\":${values.sadownloadtemplate === undefined || values.sadownloadtemplate === null ? null : values.sadownloadtemplate===true?`\\"${"Y"}\\"`:`\\"${"N"}\\"`},\\"upcSearch\\":${values.saupcsearch === undefined || values.saupcsearch === null ? null : values.saupcsearch===true?`\\"${"Y"}\\"`:`\\"${"N"}\\"`},\\"skuSearch\\":${values.saskusearch === undefined || values.saskusearch === null ? null : values.saskusearch===true?`\\"${"Y"}\\"`:`\\"${"N"}\\"`},\\"fullCount\\":${values.safullcount === undefined || values.safullcount === null ? null : values.safullcount===true?`\\"${"Y"}\\"`:`\\"${"N"}\\"`},\\"cyclicCount\\":${values.sacycliccount === undefined || values.sacycliccount === null ? null : values.sacycliccount===true?`\\"${"Y"}\\"`:`\\"${"N"}\\"`},\\"openingBalance\\":${values.saopeningbalance === undefined || values.saopeningbalance === null ? null : values.saopeningbalance===true?`\\"${"Y"}\\"`:`\\"${"N"}\\"`}}" 
        }`
      );
        console.log("appSetupArray",appSetupArray)
      const appSetupMutation = {
        query: `mutation {
          upsertAppSetup(appSetup: { 
            csAppSetup:[${appSetupArray}]
          }) {
            status
            message
        }
      }`,
      };

      Axios({
        url: serverUrl,
        method: "POST",
        data: appSetupMutation,
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${newToken.access_token}`,
        },
      }).then((response) => {
        const appSetupResponse = response.data.data.upsertAppSetup;
        if (appSetupResponse.status === "200") {
          message.success(appSetupResponse.message);
          setLoading(false);
        } else {
          message.error(appSetupResponse.message);
          setLoading(false);
        }
      });
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
    
  };

  /* const clearData = () => {
    mainForm.resetFields();
  }; */

  const onChangeCheckbox = (e) => {
    const eventId = e.target.id;
    const checkedValue = e.target.checked;
    if (eventId === "control-hooks_purchaseOrderPrintPdf") {
      if (checkedValue === true) {
        setCheckedValuePurchasePrintPdf(1);
      } else {
        setCheckedValuePurchasePrintPdf(0);
      }
    }

    if (eventId === "control-hooks_createGRN") {
      if (checkedValue === true) {
        setCheckedValueCreateGRN(1);
      } else {
        setCheckedValueCreateGRN(0);
      }
    }

    if (eventId === "control-hooks_purchaseOrderCreateInvoice") {
      if (checkedValue === true) {
        setCheckedValuePurchaseCreateInvoice(1);
      } else {
        setCheckedValuePurchaseCreateInvoice(0);
      }
    }

    if (eventId === "control-hooks_purchaseOrderSendEmail") {
      if (checkedValue === true) {
        setCheckedValuePurchaseSendEmail(1);
      } else {
        setCheckedValuePurchaseSendEmail(0);
      }
    }

    if (eventId === "control-hooks_purchaseOrderShowSummary") {
      if (checkedValue === true) {
        setCheckedValuePurchaseShowSummary(1);
      } else {
        setCheckedValuePurchaseShowSummary(0);
      }
    }

    if (eventId === "control-hooks_purchaseOrderChangePrice") {
      if (checkedValue === true) {
        setCheckedValuePurchaseChangePrice(1);
      } else {
        setCheckedValuePurchaseChangePrice(0);
      }
    }

    if (eventId === "control-hooks_purchaseOrderImport") {
      if (checkedValue === true) {
        setCheckedValuePurchaseImport(1);
      } else {
        setCheckedValuePurchaseImport(0);
      }
    }

    if (eventId === "control-hooks_purchaseOrderDownloadTemplate") {
      if (checkedValue === true) {
        setCheckedValuePurchaseDownloadTemplate(1);
      } else {
        setCheckedValuePurchaseDownloadTemplate(0);
      }
    }

    if (eventId === "control-hooks_enableWithoutPO") {
      if (checkedValue === true) {
        setCheckedValueEnableWithoutPO(1);
      } else {
        setCheckedValueEnableWithoutPO(0);
      }
    }

    if (eventId === "control-hooks_goodsReceiptCreateInvoice") {
      if (checkedValue === true) {
        setCheckedValueGRCreateInvoice(1);
      } else {
        setCheckedValueGRCreateInvoice(0);
      }
    }

    if (eventId === "control-hooks_goodsReceiptPrintPdf") {
      if (checkedValue === true) {
        setCheckedValueGRPrintPdf(1);
      } else {
        setCheckedValueGRPrintPdf(0);
      }
    }

    if (eventId === "control-hooks_goodsReceiptSendEmail") {
      if (checkedValue === true) {
        setCheckedValueGRSendEmail(1);
      } else {
        setCheckedValueGRSendEmail(0);
      }
    }

    if (eventId === "control-hooks_goodsReceiptShowSummary") {
      if (checkedValue === true) {
        setCheckedValueGRShowSummary(1);
      } else {
        setCheckedValueGRShowSummary(0);
      }
    }

    if (eventId === "control-hooks_GRNChangePrice") {
      if (checkedValue === true) {
        setCheckedValueGRChangePrice(1);
      } else {
        setCheckedValueGRChangePrice(0);
      }
    }

    if (eventId === "control-hooks_GRNImport") {
      if (checkedValue === true) {
        setCheckedValueGRImport(1);
      } else {
        setCheckedValueGRImport(0);
      }
    }

    if (eventId === "control-hooks_GRNDownloadTemplate") {
      if (checkedValue === true) {
        setCheckedValueGRDownloadTemplate(1);
      } else {
        setCheckedValueGRDownloadTemplate(0);
      }
    }

    if (eventId === "control-hooks_GRNFreeQty") {
      if (checkedValue === true) {
        setCheckedValueGRFreeQty(1);
      } else {
        setCheckedValueGRFreeQty(0);
      }
    }

    if (eventId === "control-hooks_GRNMRP") {
      if (checkedValue === true) {
        setCheckedValueGRMRP(1);
      } else {
        setCheckedValueGRMRP(0);
      }
    }

    if (eventId === "control-hooks_GRNSalePrice") {
      if (checkedValue === true) {
        setCheckedValueGRSalePrice(1);
      } else {
        setCheckedValueGRSalePrice(0);
      }
    }

    if (eventId === "control-hooks_GRNBasePrice") {
      if (checkedValue === true) {
        setCheckedValueGRBasePrice(1);
      } else {
        setCheckedValueGRBasePrice(0);
      }
    }

    if (eventId === "control-hooks_GRNActualPriceMrp") {
      if (checkedValue === true) {
        setCheckedValueGRActualPriceMRP(1);
      } else {
        setCheckedValueGRActualPriceMRP(0);
      }
    } 

    if (eventId === "control-hooks_GRNUnitPrice") {
      if (checkedValue === true) {
        setCheckedValueGRUnitPrice(1);
      } else {
        setCheckedValueGRUnitPrice(0);
      }
    }

    if (eventId === "control-hooks_GRNetUnitPrice") {
      if (checkedValue === true) {
        setCheckedValueGRNetUnitPrice(1);
      } else {
        setCheckedValueGRNetUnitPrice(0);
      }
    } 

    if (eventId === "control-hooks_GRNMargin") {
      if (checkedValue === true) {
        setCheckedValueGRMargin(1);
      } else {
        setCheckedValueGRMargin(0);
      }
    }

    if (eventId === "control-hooks_GRNOldMargin") {
      if (checkedValue === true) {
        setCheckedValueGROldMargin(1);
      } else {
        setCheckedValueGROldMargin(0);
      }
    }

    if (eventId === "control-hooks_GRNNetAmount") {
      if (checkedValue === true) {
        setCheckedValueGRNetAmount(1);
      } else {
        setCheckedValueGRNetAmount(0);
      }
    }
    
    if (eventId === "control-hooks_GRNGrossAmount") {
      if (checkedValue === true) {
        setCheckedValueGRGrossAmount(1);
      } else {
        setCheckedValueGRGrossAmount(0);
      }
    }

    if (eventId === "control-hooks_GRNTotalDiscount") {
      if (checkedValue === true) {
        setCheckedValueGRTotalDiscount(1);
      } else {
        setCheckedValueGRTotalDiscount(0);
      }
    }

    if (eventId === "control-hooks_GRNDiscountValue") {
      if (checkedValue === true) {
        seCheckedValueGRDiscountValue(1);
      } else {
        seCheckedValueGRDiscountValue(0);
      }
    }

    if (eventId === "control-hooks_GRNDiscountType") {
      if (checkedValue === true) {
        setCheckedValueGRDiscountType(1);
      } else {
        setCheckedValueGRDiscountType(0);
      }
    }



  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getAllFormValues = (e,val) =>{
    console.log("e:", e);
    console.log("val:", val);
  }

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: "52px" }} spin />} spinning={loading}>
      <div>
        <Row>
          <Col span={12}>
            <h2>Application Setup</h2>
          </Col>
          <Col span={10} />
          <Col span={2}>
            <Button style={Themes.contentWindow.ListWindowHeader.newButtonForlist} type="primary" onClick={saveData}>
              Save
            </Button>
            &nbsp;
          </Col>
          {/* <Col span={2}>
            <Button style={Themes.contentWindow.ListWindowHeader.newButtonForlist} onClick={clearData}>
              Cancel
            </Button>
          </Col> */}
        </Row>
        <Row>
          <Col span={24}>
            <Form layout="vertical" form={mainForm} name="control-hooks" onValuesChange={getAllFormValues} onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Card>
                <Tabs defaultActiveKey={"PO"} onChange={callBackTabs}>
                  <TabPane tab="Purchase" key="PO">
                    <div style={{ padding: "8px" }}>
                      <h3>Purchase Order</h3>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={2} />
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="basePriceCalculation" label="Base Price Calculation" style={{ marginBottom: "8px" }}>
                            <Select
                              allowClear
                              showSearch
                              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              /* onFocus={getBusinessUnit}
                        onSelect={onSelectBusinessUnit} */
                            >
                              <Option key="2" value="Actual Cost Price" title="Actual Cost Price">
                                Actual Cost Price
                              </Option>

                              <Option key="1" value="Purchase Price" title="Purchase Price">
                                Purchase Price
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Print PDF" name="purchaseOrderPrintPdf">
                            <Checkbox
                              checked={checkedValuePurchasePrintPdf}
                              onChange={onChangeCheckbox}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Create GRN" name="createGRN">
                            <Checkbox checked={checkedValueCreateGRN} onChange={onChangeCheckbox} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col className="gutter-row" span={2} />
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Create Invoice" name="purchaseOrderCreateInvoice">
                            <Checkbox
                              checked={checkedValuePurchaseCreateInvoice}
                              onChange={onChangeCheckbox}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Send Email" name="purchaseOrderSendEmail">
                            <Checkbox
                              checked={checkedValuePurchaseSendEmail}
                              onChange={onChangeCheckbox}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Show Summary" name="purchaseOrderShowSummary">
                            <Checkbox
                              checked={checkedValuePurchaseShowSummary}
                              onChange={onChangeCheckbox}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={2} />
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Change Price" name="purchaseOrderChangePrice">
                            <Checkbox
                              checked={checkedValuePurchaseChangePrice}
                              onChange={onChangeCheckbox}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Import" name="purchaseOrderImport">
                            <Checkbox checked={checkedValuePurchaseImport} onChange={onChangeCheckbox} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Download Template" name="purchaseOrderDownloadTemplate">
                            <Checkbox
                              checked={checkedValuePurchaseDownloadTemplate}
                              onChange={onChangeCheckbox}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>

                      <br />
                    </div>
                  </TabPane>
                  <TabPane tab="Goods Receipt" key="GRN">
                    <div style={{ padding: "8px" }}>
                      <h3>Goods Receipt</h3>
                      <Row gutter={16}>
                        <Col className="gutter-row" span={2} />
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="basePriceCalculationGRN" label="Base Price Calculation" style={{ marginBottom: "8px" }}>
                            <Select
                              allowClear
                              showSearch
                              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              /* onFocus={getBusinessUnit}
                        onSelect={onSelectBusinessUnit} */
                            >
                              <Option key="2" value="Actual Cost Price" title="Actual Cost Price">
                                Actual Cost Price
                              </Option>

                              <Option key="1" value="Purchase Price" title="Purchase Price">
                                Purchase Price
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Enable Without PO" name="enableWithoutPO">
                            <Checkbox
                              checked={checkedValueEnableWithoutPO}
                              onChange={onChangeCheckbox}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Print PDF" name="goodsReceiptPrintPdf">
                            <Checkbox checked={checkedValueGRPrintPdf} onChange={onChangeCheckbox} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>

                      <br />

                      <Row gutter={16}>
                        <Col className="gutter-row" span={2} />
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Create Invoice" name="goodsReceiptCreateInvoice">
                            <Checkbox
                              checked={checkedValueGRCreateInvoice}
                              onChange={onChangeCheckbox}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Send Email" name="goodsReceiptSendEmail">
                            <Checkbox checked={checkedValueGRSendEmail} onChange={onChangeCheckbox} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Show Summary" name="goodsReceiptShowSummary">
                            <Checkbox checked={checkedValueGRShowSummary} onChange={onChangeCheckbox} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={2} />
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Change Price" name="GRNChangePrice">
                            <Checkbox checked={checkedValueGRChangePrice} onChange={onChangeCheckbox} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Import" name="GRNImport">
                            <Checkbox checked={checkedValueGRImport} onChange={onChangeCheckbox} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Download Template" name="GRNDownloadTemplate">
                            <Checkbox
                              checked={checkedValueGRDownloadTemplate}
                              onChange={onChangeCheckbox}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />

                      <Row gutter={16}>
                        <Col className="gutter-row" span={2} />
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Free Qty" name="GRNFreeQty">
                            <Checkbox checked={checkedValueGRFreeQty} onChange={onChangeCheckbox} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="MRP" name="GRNMRP">
                            <Checkbox checked={checkedValueGRMRP} onChange={onChangeCheckbox} style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Sale Price" name="GRNSalePrice">
                            <Checkbox
                              checked={checkedValueGRSalePrice}
                              onChange={onChangeCheckbox}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={2} />
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Base Price" name="GRNBasePrice">
                            <Checkbox  
                            checked={checkedValueGRBasePrice}
                            onChange={onChangeCheckbox}
                            style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Actual Price > Mrp" name="GRNActualPriceMrp">
                            <Checkbox  
                             checked={checkedValueGRActualPriceMRP}
                            onChange={onChangeCheckbox}
                            style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Unit Price" name="GRNUnitPrice">
                            <Checkbox  
                             checked={checkedValueGRUnitPrice}
                             onChange={onChangeCheckbox}
                            style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={2} />
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Net Unit Price" name="GRNetUnitPrice">
                            <Checkbox  
                            checked={checkedValueGRNetUnitPrice}
                            onChange={onChangeCheckbox}
                            style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Margin" name="GRNMargin">
                            <Checkbox  
                            checked={checkedValueGRMargin}
                            onChange={onChangeCheckbox}
                            style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Old Margin" name="GRNOldMargin">
                            <Checkbox  
                            checked={checkedValueGROldMargin}
                            onChange={onChangeCheckbox}
                            style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                      
                      </Row>
                      <br />
                      <Row gutter={16}>
                        <Col className="gutter-row" span={2} />
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Net Amount" name="GRNNetAmount">
                            <Checkbox  
                            checked={checkedValueGRNetAmount}
                            onChange={onChangeCheckbox}
                            style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Gross Amount" name="GRNGrossAmount">
                            <Checkbox
                              checked={checkedValueGRGrossAmount}
                              onChange={onChangeCheckbox}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Total Discount" name="GRNTotalDiscount">
                            <Checkbox
                              checked={checkedValueGRTotalDiscount}
                              onChange={onChangeCheckbox}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      
                      <Row gutter={16}>
                        <Col className="gutter-row" span={2} />
                        <Col className="gutter-row" span={6}>
                          <Form.Item label="Discount Value" name="GRNDiscountValue">
                            <Checkbox  
                            checked={checkedValueGRDiscountValue}
                            onChange={onChangeCheckbox}
                            style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={4} />
                        <Col className="gutter-row" span={4}>
                          <Form.Item label="Discount Type" name="GRNDiscountType">
                            <Checkbox  
                            checked={checkedValueGRDiscountType}
                            onChange={onChangeCheckbox}
                            style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}></Checkbox>
                          </Form.Item>
                        </Col>
                       
                      </Row>
                      <br />
                    </div>
                  </TabPane>
                  <TabPane tab="Stock Adjustment" key="Stock Adjustment">
                    <StockAdjustment stockAdjustmentValues={stockAdjustmentValues} />
                  </TabPane>
                  <TabPane tab="Stock Transfers" key="4">
                    Stock Transfers
                  </TabPane>
                </Tabs>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default ApplicationSetup;
