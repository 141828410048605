import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, Select, Button, Modal, Spin, Table, Image, Input, DatePicker, Radio, Space, Tabs, message, InputNumber } from "antd";
import { LoadingOutlined, PlusCircleOutlined, PrinterOutlined, EditOutlined, DeleteOutlined, SearchOutlined, DownOutlined } from "@ant-design/icons";
import { v4 as uuid } from "uuid";
import AuditTrail from "../../../assets/images/auditTrail.svg";
import Arrow from "../../../assets/images/arrow.svg";
import { getSoProductData, getAddonProductData, getRoleBusinessUnit } from "../../../services/custom";
import { getOrderTypeData, getNewSalesCustomerData, getNewSalesPrice, getNewSalesRep, getCustomerAddressData, getOrderFormData, getSalesTypeData } from "../../../services/generic";
import moment from "moment";
import { serverUrl, genericUrl } from "../../../constants/serverConfig";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import "antd/dist/antd.css";
import "../../../styles/antd.css";

const { Option } = Select;
const { TabPane } = Tabs;
const dateFormat = "YYYY-MM-DD";

const NewSalesOrder = () => {
  const inputRef = React.useRef(null);

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [headerform] = Form.useForm();
  const [detailsForm] = Form.useForm();

  const [soOrderId, setSoOrderId] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [sku, setSku] = useState("");
  const [description, setDescription] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [orderTypeId, setOrderTypeId] = useState("");
  const [customerId, setCustomerID] = useState("");
  const [customerAdd, setCustomerAdd] = useState("");
  const [schDelryDate, setSchDelryDate] = useState("");
  const [price, setPrice] = useState("");
  const [salesRep, setSalesRep] = useState("");
  const [productId, setProductId] = useState("");
  const [uomId, setUomId] = useState("");
  const [taxId, setTaxId] = useState("");
  const [priceValue, setPriceValue] = useState("1");
  const [salesTypeData, setSalesTypeData] = useState([]);
  const [orderFormData, setOrderFormData] = useState([]);
  const [tableData, setTableData] = useState([]);
  // const [changedValue, setChangedValue] = useState("");
  // const [changedKey, setChangedKey] = useState("");
  // const [changedIndex, setChangedIndex] = useState("");
  const [subId, setSubId] = useState("");
  const [solitaireData, setSolitaireData] = useState([]);
  const [solitaireId, setSolitaireId] = useState("");
  const [qtyValue, setQtyValue] = useState("0");

  const [bUnitId, setBUnitId] = useState("");
  const [value, setValue] = useState(null);
  // const [isFlag, setIsFlag] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [detailsVisbile, setDetailsVisbile] = useState(false);
  const [isDisplayVisible, setIsDisplayVisible] = useState(false);
  const [orderTypeData, setOrderTypeData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const [salesRepData, setSalesRepData] = useState([]);
  const [address, setAddress] = useState([]);
  const [metalData, setMetalData] = useState([]);
  const [costPrice, setCostPrice] = useState([]);
  const [metalValue, setMetalValue] = useState("");

  const [productDetails, setProductDetails] = useState([]);
  const [id, setId] = useState(null);
  const [soProductData, setSoProductData] = useState([]);
  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    const initialDate = moment(new Date()).format("YYYY-MM-DD");
    setSelectedDate(initialDate);
    setSchDelryDate(initialDate);
    getBusinessUnit();
    headerform.setFieldsValue({
      orderDate: moment(initialDate, dateFormat),
      schDeliveryDate: moment(initialDate, dateFormat),
    });
  }, []);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //      setCount(1);
  //    }, 3000);

  //   return () => clearTimeout(timeout);
  //  },[count]);
  const columns = [
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "costPrice",
      key: "costPrice",
    },
  ];

  const dataColumns = [
    {
      title: "Shape",
      dataIndex: "DS_Shape",
      key: "DS_Shape",
      width: 80,
    },
    {
      title: "Carat",
      dataIndex: "DS_SIZE",
      key: "DS_SIZE",
      width: 80,
    },

    {
      title: "Color",
      dataIndex: "DS_Colour",
      key: "DS_Colour",
      width: 80,
    },
    {
      title: "Clarity",
      dataIndex: "DS_Clarity",
      key: "DS_Clarity",
      width: 80,
    },
    // {
    //   title: "Quantity",
    //   dataIndex: "reqQty",
    //   width: 80,
    //   render: (text, record, index) => <InputNumber size="small" style={{ width: "95%" }} min={0} max={1000000000} value={text} onChange={onInputChange("reqQty", index)} />,
    // },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 80,
    },
  ];

  const getDateChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  const getSoProduct = async (searchKey) => {
    const soProductResponse = await getSoProductData(searchKey);
    // console.log("soProductResponse============>", soProductResponse);
    let filteredData1 = soProductResponse.filter((values) => {
      return values.name?.toLowerCase().includes(value?.toLowerCase());
    });
    setFilteredData(filteredData1);
    setLoading(false);
  };

  const getAddonProduct = async (key) => {
    let addonProductResponse = await getAddonProductData(key);

    // console.log("addonProductResponse=============>", addonProductResponse);
    let metalNames = [];
    // let arry = [...metalData];
    let data = [];
    for (let i = 0; i < addonProductResponse.length; i++) {
      if (addonProductResponse[i].productCategory.name === "Solitaires") {
        addonProductResponse[i].productAttributes.price = addonProductResponse[i].costPrice;
        data.push(addonProductResponse[i]);
      } else {
        // addonProductResponse[i].key = uniqueId;
        metalNames.push(addonProductResponse[i]);
      }
    }

    // let newArray = arry.concat([metalNames]);
    // let finalArry = [...new Set(newArray)];
    // console.log("value============", newArray);

    setMetalData(metalNames);

    let newArry = [];
    data.map((value) => {
      let productData = value.productAttributes;
      let obj = {};

      productData.forEach((number) => {
        obj.mProductId = value.mProductId;
        obj.unitPrice = productData.price === null ? 0 : productData.price;
        let uniqueId = uuid().replace(/-/g, "").toUpperCase();
        obj.key = uniqueId;
        obj[number.mAttribute.name] = number.value;
      });

      newArry.push(obj);
      // console.log("obj", obj);
    });
    // console.log("newArry===========>", newArry);
    setTableData(newArry);
  };

  // const onInputChange = (key, index) => (value) => {
  //   setChangedValue(value);
  //   setChangedKey(key);
  //   setChangedIndex(index);
  // };
  // useEffect(() => {
  //   let newData = [...tableData];
  //   console.log("newData===============>", newData);
  //   console.log(newData);
  //   if (newData.length > 0) {
  //     newData[changedIndex][changedKey] = changedValue;
  //     setTableData(newData);
  //   }
  // }, [changedValue]);

  const getOrdertype = async () => {
    const orderTypeResponse = await getOrderTypeData();
    setOrderTypeData(orderTypeResponse.searchData);
  };
  const getCustomer = async () => {
    const customerDataResponse = await getNewSalesCustomerData();
    setCustomerData(customerDataResponse.searchData);
  };

  const getPrice = async () => {
    const priceDataResponse = await getNewSalesPrice();
    setPriceData(priceDataResponse.searchData);
  };
  const getOrderForm = async () => {
    const orderFromresponse = await getOrderFormData();

    setOrderFormData(orderFromresponse.searchData);
  };
  // console.log(salesTypeData);
  const getSalesTYpe = async () => {
    const response = await getSalesTypeData();
    console.log("response==>", response);

    setSalesTypeData(response.searchData);
  };
  const getSalesRep = async () => {
    const salesRepResponse = await getNewSalesRep();
    setSalesRepData(salesRepResponse.searchData);
  };

  const getCustomerAddress = async (value) => {
    const addressResponse = await getCustomerAddressData(value);

    let name = [];

    Object.entries(addressResponse).map(([key, value]) =>
      name.push({ Name: value.value ? value?.value[0]?.Name : null, RecordID: value.value ? value?.value[0]?.RecordID : null })
    );
    setAddress(addressResponse);
    // console.log("addressResponse==============>", name);
  };

  const getBusinessUnit = async () => {
    const userData = JSON.parse(window.localStorage.getItem("userData"));
    const businessUnitResponse = await getRoleBusinessUnit(userData.user_id);
    // console.log("customerResponse====>", businessUnitResponse);
    setBUnitId(businessUnitResponse.defaultCsBunitId);
  };

  const onSelectOrderType = (e, data) => {
    setOrderTypeId(e);
  };
  const onCustomerData = (e, data) => {
    setCustomerID(e);
    getCustomerAddress(e);
  };

  const getCustomerAdd = (e) => {
    setCustomerAdd(e);
  };
  const onSelectDate = (date, dateString) => {
    setSchDelryDate(dateString);
  };
  const onSalesRep = (e) => {
    setSalesRep(e);
  };
  const selectOrderForm = (e) => {
    console.log(e);
  };
  const selectsalesType = (e) => {
    console.log(e);
  };

  const onpriceData = (e, data) => {
    setPrice(e);
  };

  const cancel = () => {
    setValue("");
    setLoading(false);
    setModalVisible(false);
  };

  const confirm = () => {
    setValue("");
    setModalVisible(false);
  };

  const onOkay = () => {
    const uniqueId = uuid().replace(/-/g, "").toUpperCase();
    const formFieldsData = detailsForm.getFieldsValue(true);
    // console.log(formFieldsData);
    let index;
    let data = [];
    index = productDetails.findIndex((element) => {
      return element.id === id;
    });
    // console.log("productDetails==========>", productDetails);
    if (index >= 0) {
      for (let i = 0; i < productDetails.length; i++) {
        if (productDetails[i].id === id) {
          console.log("productDetails", productDetails[i]);
          // console.log('metal', )

          productDetails[i].sku = formFieldsData.sku;
          productDetails[i].price = formFieldsData.price;
          productDetails[i].ringSize = formFieldsData.ringSize;
          productDetails[i].metal = formFieldsData.metal;
          productDetails[i].qty = formFieldsData.qty;
          productDetails[i].solitaire = tableData;
          productDetails[i].solitaireData = solitaireData;
          productDetails[i].metalData = metalData;
        }
      }
    } else {
      console.log(metalData);
      let newObj = {
        sku: formFieldsData.sku,
        price: formFieldsData.price,
        ringSize: formFieldsData.ringSize,
        metal: formFieldsData.metal,
        qty: formFieldsData.qty,
        id: uniqueId,
        imgUrl: imgUrl,
        solitaire: tableData,
        solitaireData: solitaireData,
        metalData: metalData,
      };
      data.push(newObj);
    }
    setImgUrl("");
    console.log("data", data);
    let finalArry = [...productDetails, ...data];
    detailsForm.resetFields();
    setLoading(false);
    setProductDetails(finalArry);
    setDetailsVisbile(true);
    setModalVisible(false);

    if (solitaireData.length > 0) {
      setSolitaireId(solitaireData.mProductId);
    }
  };

  const modalView = (e) => {
    setValue(e.target.value);
    setModalVisible(true);
    setIsDisplayVisible(true);
    setDetailsVisbile(true);
  };

  const onChange = (e) => {
    setLoading(true);
    getSoProduct(e.target.value);
    setValue(e.target.value);
  };

  // const viewInput = () => {
  //   setIsFlag(true);
  // };

  const getFieldData = (data) => {
    setValue("");
    setImgUrl(data.imageurl);
    setUomId(data.uom.csUomId);

    setProductId(data.mProductId);
    detailsForm.resetFields(["ringSize", "metal", "qty"]);
    detailsForm.setFieldsValue({
      sku: data.name,
      price: data.productAddons[0]?.price,
      qty: priceValue,
    });
  };

  const editProduct = (text) => {
    setModalVisible(true);
    setDetailsVisbile(false);
    setId(text.id);
    setTableData(text.solitaire);
    setImgUrl(text.imgUrl);
    setMetalData(text.metalData);
    console.log(metalData);
    console.log(text.solitaire);

    detailsForm.setFieldsValue({
      // sku: text.sku,
      price: text.price,
      ringSize: text.ringSize,
      metal: text.metal,
      qty: text.qty,
    });
  };
  const onSelectProductData = (e, data) => {
    if (e.length <= 1) {
      setSelectedRowKeys(e);
      setSolitaireData(data);
    }
  };

  const rowSelection = {
    onChange: onSelectProductData,
  };
  const onFinish = () => {
    // setLoading();
  };
  const getValue = (e) => {
    // console.log(metalData);
    // setMetalValue("");
    setMetalValue(e.target.value);
    // let newData = []
    for (let i = 0; i < metalData.length; i++) {
      if (metalData[i].value === e.target.value) {
        setSubId(metalData[i].mProductId);
      }
    }
  };

  const changePrice = (e) => {
    // console.log("value===========>", e.target.value);
    setPriceValue(e.target.value);
  };

  const deleteRow = (e) => {
    const dataSource = [...productDetails];
    let newData = [];
    dataSource.filter((item) => {
      if (item.id !== e.id) {
        newData.push(item);
      }
    });
    setProductDetails(newData);
  };
  const selectRow = (record) => {
    return {
      onClick: (event) => {
        console.log(record);
        setTaxId(record.taxRate.csTaxID);
        getFieldData(record);
        getAddonProduct(record.mProductId);
        setSku(record.name);
        if (record.description !== null) {
          let text = record.description.replaceAll("<h1>", "").replaceAll("</h1>", "").replaceAll("<div>", "").replaceAll("</div>", "").replaceAll("<br>", "");
          setDescription(text);
        } else {
          setDescription("");
        }

        // setCostPrice(record.costPrice);
        setDetailsVisbile(!detailsVisbile);
        setIsDisplayVisible(false);
      },
    };
  };
  useEffect(() => {
    inputRef.current?.focus({
      cursor: "end",
    });
  }, [modalView]);

  const sharedProps = {
    ref: inputRef,
  };

  const getQtyValue = (e) => {
    setQtyValue(e.target.value);
  };

  const getData = () => {
    const userData = JSON.parse(window.localStorage.getItem("userData"));

    const newToken = JSON.parse(localStorage.getItem("authTokens"));
    const uniqueId = uuid().replace(/-/g, "").toUpperCase();
    setLoading(true);

    try {
      const createPoOrder = {
        query: `mutation {
          createCustomSalesOrder (sOrder : {
              cSBunitID:"${bUnitId}"
              createdby:"${userData.user_id}"
              sOrderID:"${uniqueId}"
              csDoctypeId:"${orderTypeId}"
              orderDate:"${selectedDate} " 
              sCustomerId:"${customerId}"
              sCustomerShippingId:"${customerAdd}"
              deliveryDate:"${schDelryDate}"
              sPricelistId:"${price}"
              description:"Good"
              salesRepId:${salesRep ? `"${salesRep}"` : null}
              cwcChannelId:null
              cwrSaleTypeId:null
              deliverynotes:"Good"
              orderreference:"30"
              nettotal:10
              grosstotal:20
              taxamt:10
          }) {
          id
          status
          message
          }
        }`,
      };
      Axios({
        url: serverUrl,
        method: "POST",
        data: createPoOrder,
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${newToken.access_token}`,
        },
      }).then((response) => {
        console.log("response==========", response);
        const Status = response.data.data.createCustomSalesOrder.status;
        const messageForSuccess = response.data.data.createCustomSalesOrder.message;

        if (Status === "200") {
          let recordId = response.data.data.createCustomSalesOrder.id;
          // console.log("response.data.data.createCustomSalesOrder.id", response.data.data.createCustomSalesOrder.id);
          // console.log(res)

          // setSoOrderId(recordId);
          headerform.resetFields();
          let uniqueId = uuid().replace(/-/g, "").toUpperCase();
          const arrayForMutation = [];

          for (let index = 0; index < solitaireData.length; index++) {
            console.log(solitaireData[index]);
            let uniqueId2 = uuid().replace(/-/g, "").toUpperCase();

            arrayForMutation.push(
              `{
                sOrderLineAddonsId:"${uniqueId2}"
                sOrderLineId:"${uniqueId}"
                mProductId: "${solitaireData[index].mProductId}"
                qty:"${solitaireData[index].DS_SIZE}"
                price:"${solitaireData[index].unitPrice}"
            }
              `
            );
          }
          for (let index = 0; index < metalData.length; index++) {
            console.log(metalData);
            console.log(solitaireData[index]);
            let uniqueId2 = uuid().replace(/-/g, "").toUpperCase();
            if (metalData[index].value === metalValue) {
              arrayForMutation.push(
                `{
                  sOrderLineAddonsId:"${uniqueId2}"
                  sOrderLineId:"${uniqueId}"
                  mProductId: "${metalData[index].mProductId}"
                  qty:1
                  price:"${metalData[index].costPrice === null || "undefined" ? 0 : metalData[index].costPrice}"
              }
                `
              );
            }
          }

          console.log(recordId);
          const orderData = {
            query: `mutation {
              upsertSalesOrderLine (sOrderLine : {
                  cSBunitID:"${bUnitId}"  
                  createdby:"${userData.user_id}"  
                  sOrderId:"${recordId}" 
                  sOrderlineID:"${uniqueId}"
                  mProductId:"${productId}"
                  csUomId:"${uomId}" 
                  taxId:"${taxId}"  
                  line:1 
                  qty:${qtyValue}
                  unitprice:10
                  listprice:null
                  linenet:null
                  linetax:null
                  linegross:null
                subProducts : [${arrayForMutation}]
              }) {
              status
              message
              }
            }

            `,
          };
          Axios({
            url: serverUrl,
            method: "POST",
            data: orderData,
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${newToken.access_token}`,
            },
          }).then((response) => {
            console.log("soOrderId", response);
            const Status = response.data.data.upsertSalesOrderLine.status;
            if (Status === "200") {
              const data1 = {
                query: `mutation {
                      runProcess(recordId:"${recordId}", ad_tab_id:"270EED9D0E7F4C43B227FEDC44C5858F", ad_field_id:"A15626E911C0419A92CC6ADBEE4F9649",parameters:"{}")
                        {
            messageCode,
            title,
            message
            }
                    }
                    `,
              };
              Axios({
                url: genericUrl,
                method: "POST",
                data: data1,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `bearer ${newToken.access_token}`,
                },
              }).then((response) => {
                console.log("data=======>", response);
                message.success(response.data.data.runProcess.message);
                history.push(`/window/7004/${recordId}`);
                setMetalData([]);
                setSolitaireData([]);
                setProductDetails([]);
                setLoading(false);
              });
            } else {
              message.error(messageForSuccess);
              setLoading(false);
            }
          });
        } else {
          message.error("getting error while creating lines");
          setLoading(false);
        }
      });
    } catch (error) {
      message.error("Please select products to proceed!");
      console.log("=====Error=====", error);
    }
  };

  return (
    <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
      <Row>
        <Col span={12}>
          <h2 style={{ fontWeight: "500", fontSize: "12px", color: "#1648AA", marginBottom: "0px", marginTop: "1%" }}>New Sales Order</h2>
          {/* <span style={{ fontWeight: "500", fontSize: "25px", color: "#161537", marginBottom: "0px", marginTop: "0px", paddingRight: "10px" }}>
            1250 Sunshine Star Enterprises &nbsp;
            <img style={{ marginTop: "-5px" }} src={AuditTrail} alt="AuditTrail" />
          </span> */}
        </Col>
        <Col span={11}>
          <span style={{ float: "right" }}>
            <Button onClick={getData} style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>
              Book
            </Button>
            <Button style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>
              More
              <DownOutlined style={{ fontSize: "10px" }} />
            </Button>
          </span>
        </Col>
      </Row>
      {/* <Row>
        <Col span={12}></Col>
        <Col span={12} style={{ marginTop: "-22px" }}>
          <span style={{ float: "right" }}>
            <Button
              style={{
                marginBottom: "8px",
                backgroundColor: "rgb(255, 255, 255)",
                color: "#717172",
                width: "93px",
                height: "32px",
                marginRight: "5px",
                textAlign: "center",
              }}
              // onClick={getProducts}
            >
              Edit
            </Button>
            <Button
              style={{
                marginBottom: "8px",
                backgroundColor: "rgb(255, 255, 255)",
                color: "#717172",
                width: "93px",
                height: "32px",
                marginRight: "5px",
                textAlign: "center",
              }}
              // onClick={onPriview}
            >
              Cancel
            </Button>

            <Button
              style={{
                marginBottom: "8px",
                backgroundColor: "rgb(255, 255, 255)",
                color: "rgb(83, 108, 120)",
                width: "32px",
                height: "32px",
                paddingLeft: "5px",
                paddingRight: "5px",
                marginRight: "5px",
                textAlign: "center",
              }}
            >
              <PlusCircleOutlined />
            </Button>
            <Button
              style={{
                marginBottom: "8px",
                backgroundColor: "rgb(255, 255, 255)",
                color: "rgb(83, 108, 120)",
                width: "32px",
                height: "32px",
                paddingLeft: "5px",
                paddingRight: "5px",
                marginRight: "5px",
                textAlign: "center",
              }}
            >
              <PrinterOutlined />
            </Button>
          </span>
        </Col>
      </Row> */}

      <Card style={{ marginBottom: "8px" }}>
        {/* <span style={{ color: "#597380", fontSize: "12px" }}>
          status : <strong style={{ fontSize: "14px" }}>Completed</strong> |{" "}
        </span>
        <span style={{ color: "#597380", fontSize: "12px" }}>
          Customer status : <strong style={{ fontSize: "14px" }}>Completed</strong> |{" "}
        </span>
        <span style={{ color: "#597380", fontSize: "12px" }}>
          Currency :{" "}
          <strong style={{ color: "#0C4DA9", fontSize: "14px" }}>
            USD &nbsp;
            <img style={{ marginTop: "-5px" }} src={Arrow} alt="Arrow" />
          </strong>{" "}
          |{" "}
        </span>
        <span style={{ color: "#597380", fontSize: "12px" }}>
          Payment status : <strong style={{ fontSize: "14px" }}>Completed</strong>
        </span> */}
        <Form layout="vertical" form={headerform} onFinish={onFinish} name="headerForm" style={{ marginTop: "20px" }}>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="orderType"
                label="Order type"
                rules={[
                  {
                    required: true,
                    message: "Please select business bunit!",
                  },
                ]}
                style={{ color: "#8E8E8E", fontSize: "13px" }}
              >
                <Select onSelect={onSelectOrderType} onFocus={getOrdertype} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {orderTypeData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item name="orderId" label="Order Id" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <Input readOnly style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item name="orderDate" label="Order Date" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <DatePicker onChange={getDateChange} style={{ width: "100%", borderLeft: "none", borderTop: "none", borderRight: "none" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <p />
            <br />
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item name="schDeliveryDate" label="Sch Delivery Date" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <DatePicker onChange={onSelectDate} style={{ width: "100%", borderLeft: "none", borderTop: "none", borderRight: "none" }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="customer"
                rules={[
                  {
                    required: true,
                    message: "Please select business bunit!",
                  },
                ]}
                label="Customer"
                style={{ color: "#8E8E8E", fontSize: "13px" }}
              >
                <Select onSelect={onCustomerData} onFocus={getCustomer} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {customerData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please select business bunit!",
                  },
                ]}
                label="Address"
                style={{ color: "#8E8E8E", fontSize: "13px" }}
              >
                <Select onSelect={getCustomerAdd} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {Object.entries(address).map(([key, value]) => (
                    <Option
                      key={value.value ? value?.value[0]?.RecordID : null}
                      value={value.value ? value?.value[0]?.RecordID : null}
                      title={value.value ? value?.value[0]?.Name : null}
                    >
                      {value.value ? value?.value[0]?.Name : null}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <p />
            <br />
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="priceList"
                rules={[
                  {
                    required: true,
                    message: "Please select business bunit!",
                  },
                ]}
                label="Price List"
                style={{ color: "#8E8E8E", fontSize: "13px" }}
              >
                <Select onSelect={onpriceData} onFocus={getPrice} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {priceData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item name="salesRep" label="Sales Rep" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <Select onSelect={onSalesRep} onFocus={getSalesRep} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {salesRepData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item name="orderFrom" label="Order From" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <Select onSelect={selectOrderForm} onFocus={getOrderForm} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {orderFormData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <p />
          <br />
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item name="saleType" label="Sale Type" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <Select onSelect={selectsalesType} onFocus={getSalesTYpe} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {salesTypeData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <br />
        </Form>
      </Card>
      <Card style={{ marginBottom: "8px", height: "47px" }}>
        <Row>
          {/* <Col className="gutter-row" span={3}>
            <div style={{ width: "93px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "5px" }}>
              <span onClick={viewInput} style={{ color: "rgb(0 0 0 / 65%)", cursor: "pointer" }}>
                + Add New
              </span>
            </div>
          </Col> */}
          <Col className="gutter-row" span={12}>
            <span>
              <Input placeholder="Search" prefix={<SearchOutlined />} onChange={modalView} name="modal" value={value} />
            </span>
          </Col>
        </Row>
      </Card>
      {productDetails.map((product, ind) => (
        <div style={{ marginBottom: "8px" }}>
          <Card bodyStyle={{ height: "135px" }}>
            <Row gutter={16}>
              <Col className="gutter-row" span={3}>
                <Image src={product.imgUrl} style={{ height: "91px", width: "91px" }} />
              </Col>
              <Col className="gutter-row" span={6}>
                <h1 style={{ color: "#8E8E8E", fontSize: "13px" }}>Design</h1>
              </Col>
              <Col className="gutter-row" span={3}>
                <h1 style={{ color: "#8E8E8E", fontSize: "13px" }}>Qty</h1>
              </Col>
              <Col className="gutter-row" span={3}>
                <h1 style={{ color: "#8E8E8E", fontSize: "13px" }}>Unit Price</h1>
              </Col>
              <Col className="gutter-row" span={6}></Col>

              <Col className="gutter-row" span={3}>
                <span style={{ float: "right" }}>
                  <EditOutlined style={{ paddingRight: "15px", fontSize: "18px", color: "#8E8E8E", cursor: "pointer" }} onClick={() => editProduct(product)} />
                  <DeleteOutlined onClick={() => deleteRow(product)} style={{ paddingRight: "15px", fontSize: "18px", color: "#8E8E8E", cursor: "pointer" }} />
                </span>
              </Col>
            </Row>

            <div
              style={{
                marginTop: "-74px",
              }}
            >
              <Row gutter={16}>
                <Col className="gutter-row" span={3}></Col>
                <Col className="gutter-row" span={6}>
                  <h1 style={{ fontSize: "13px" }}>{product.sku}</h1>
                </Col>
                <Col className="gutter-row" span={3}>
                  <h1 style={{ fontSize: "13px" }}>{product.qty}</h1>
                </Col>
                <Col className="gutter-row" span={3}>
                  <h1 style={{ fontSize: "13px" }}>{product.price}</h1>
                </Col>
                <Col className="gutter-row" span={9}></Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row" span={3}></Col>
                <Col className="gutter-row" span={21}>
                  <div>
                    {" "}
                    {product.metal ? `${product.metal}` : null}
                    {product?.solitaireData.length > 0 ? ` ${product.solitaireData[0]?.DS_Clarity}-${product.solitaireData[0]?.DS_SIZE} Solitaire` : null}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      ))}

      <Modal width="85%" centered visible={modalVisible} closable={true} okText="Save" onOk={!detailsVisbile ? onOkay : confirm} onCancel={cancel}>
        {isDisplayVisible ? (
          <Card style={{ height: "50vh" }}>
            <Input name="modal" {...sharedProps} value={value} onChange={onChange} />
            <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
              {filteredData.length > 0 ? (
                <Table size="small" columns={columns} onRow={selectRow} dataSource={filteredData} pagination={false} style={{ height: "44vh", overflow: "scroll" }} />
              ) : null}
            </Spin>
          </Card>
        ) : (
          ""
        )}

        {!detailsVisbile ? (
          <div>
            {/* <Tabs defaultActiveKey="1" type="card">
              <TabPane tab="Metal" key="1">
              <Form.Item name="sku" label="SKU" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                        <Input />
                      </Form.Item>
              
              */}
            <Row>
              <Col span={8}>
                <img src={imgUrl} style={{ marginLeft: "8px", width: "350px", height: "350px" }} />
              </Col>

              <Col span={16} style={{ marginTop: "-5px", marginLeft: "-1px" }}>
                <span style={{ marginLeft: "16px", fontSize: "22px", font: "normal normal 600 22px/30px  Open Sans", color: "#666666" }}>{`${sku} ${description}`}</span>
                <Form form={detailsForm} name="detailsForm" layout="vertical" style={{ marginTop: "20px", marginLeft: "16px" }}>
                  <Row gutter={16}>
                    <Col className="gutter-row" span={3} style={{ height: "58px" }}>
                      <Form.Item name="ringSize" label="Ring Size" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                        <Select defaultValue={"12"}>
                          <Option key={1} value={"12"}>
                            12
                          </Option>
                          <Option key={2} value={"13"}>
                            13
                          </Option>
                          <Option key={3} value={"14"}>
                            14
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={1} style={{ height: "58px", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "15px" }}>
                      {" "}
                      <span style={{ color: "#5D5454", fontSize: "13px" }}>to</span>
                    </Col>
                    <Col className="gutter-row" span={3} style={{ height: "58px" }}>
                      <Form.Item style={{ color: "#8E8E8E", fontSize: "13px", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "22px" }}>
                        <Select defaultValue={"18"}>
                          <Option key={0} value={"14"}>
                            14
                          </Option>
                          <Option key={1} value={"15"}>
                            15
                          </Option>
                          <Option key={2} value={"16"}>
                            16
                          </Option>
                          <Option key={3} value={"17"}>
                            17
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={3} style={{ height: "58px" }}>
                      <Form.Item name="qty" label="Qty" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                        <Input onChange={getQtyValue} />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6} style={{ height: "58px" }}>
                      <Form.Item name="price" label="Price" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                        <Input onChange={changePrice} value={priceValue} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Tabs style={{ marginTop: "-6px" }}>
                    <TabPane tab="Metal" key="1" style={{ height: "170px" }}>
                      <Row>
                        <Col className="gutter-row">
                          <Form.Item name="metal" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                            <Radio.Group onChange={getValue}>
                              <Space direction="vertical">
                                {metalData.map((data, ind) => {
                                  return (
                                    <Radio value={data.value} style={{ fontSize: "15px", color: "#666666", letterSpacing: "0.3px", marginLeft: "16px", paddingTop: "5px" }}>
                                      {data.name}
                                    </Radio>
                                  );
                                })}
                                {/* {metalData.map((data, ind) => {
                                  return data.map((item) => {
                                    if (metalData.length === ind + 1) {
                                      return (
                                        <Radio value={item.value} style={{ fontSize: "15px", color: "#666666", letterSpacing: "0.3px", marginLeft: "16px", paddingTop: "5px" }}>
                                          {item.name}
                                        </Radio>
                                      );
                                    }
                                  });
                                })} */}
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Solitaire" key="2" style={{ height: "170px" }}>
                      <Row>
                        <Col style={{ display: "flex", flex: "1", marginBottom: "8px" }}>
                          <Input placeholder="Search" prefix={<SearchOutlined />} style={{ flex: "1" }} />
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ display: "flex", flex: "1" }}>
                          <Table
                            size="small"
                            rowSelection={{ ...rowSelection, selectedRowKeys }}
                            columns={dataColumns}
                            dataSource={tableData}
                            scroll={{ y: 75 }}
                            pagination={false}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Side Diamonds" key="3">
                      Content of Tab Pane 3
                    </TabPane>
                  </Tabs>
                </Form>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
      </Modal>
    </Spin>
  );
};

export default NewSalesOrder;
