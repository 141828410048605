import React, { useState } from "react";
import { Form, Input, Checkbox, Select, Card, Col, Row, Spin, DatePicker, Upload, Button } from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { getProductCategoryAttribute } from "../../../services/custom";
import "antd/dist/antd.css";
import "../../../styles/antd.css";
import { getBunitData, getNewProductData, getSubProductData, getDesignedByData, getSketchRefData, getOrderFormData, getSalesTypeData } from "../../../services/generic";

const NewProductDesign = () => {
  const { Option } = Select;
  const [headerform] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";

  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [formData, SetFormData] = useState([]);
  const [elementValue, setElementValue] = useState("");

  // form states start

  const [bunitData, setBunitData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [subProductData, setSubProductData] = useState([]);
  const [designedByData, setDesignedByData] = useState([]);
  const [sketchData, setSkecthData] = useState([]);
  // form states end

  // service Calls start
  const getBunitId = async () => {
    const BunitResponse = await getBunitData();
    setBunitData(BunitResponse.searchData);
  };

  const getProduct = async () => {
    const productResponse = await getNewProductData();
    setProductData(productResponse.searchData);
  };
  const getSubProduct = async () => {
    const subProductResponse = await getSubProductData();
    setSubProductData(subProductResponse.searchData);
    // console.log(subProductResponse.searchData);
  };

  const getDesignedBy = async () => {
    const designedByRes = await getDesignedByData();
    setDesignedByData(designedByRes.searchData);
  };

  const getSkecthRef = async () => {
    const skecthRes = await getSketchRefData();
    setSkecthData(skecthRes.searchData);
  };
  // service Calls end
  const component = {
    ST: Input,
    DA: DatePicker,
    LI: Select,
  };

  const getINputValue = (e, key) => {
    console.log(key);
    setElementValue(e?.target?.value);
  };

  const getFormData = async (e) => {
    const FormData = await getProductCategoryAttribute(e);

    if (FormData.length > 0) {
      for (let i = 0; i < FormData?.length; i++) {
        if (FormData[i].value.includes(",")) {
          FormData[i].data = FormData[i].value.split(",");
        } else {
          FormData[i].data = [];
        }
      }
      for (let i = 0; i < FormData.length; i++) {
        if (FormData[i].type === "ST") {
          setElementValue(FormData[i].value);
        }
      }
      SetFormData(FormData);

      setFormVisible(true);
    } else {
      SetFormData([]);

      setFormVisible(false);
    }
    console.log(FormData);
  };

  const Onselect = (e) => {
    getFormData(e);
  };
  const onChange = async (e) => {
    const subProductResponse = await getSubProductData(e);
    setSubProductData(subProductResponse.searchData);
  };

  return (
    <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
      <Card style={{ marginBottom: "8px" }}>
        <Form layout="vertical" form={headerform}>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Business Unit"
                name="businessUnit"
                rules={[
                  {
                    required: true,
                    message: "Please select Business Unit!",
                  },
                ]}
                style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}
              >
                <Select allowClear showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} onFocus={getBunitId}>
                  {bunitData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Product" name="product" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                <Select allowClear showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} onFocus={getProduct}>
                  {productData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Sub Product" name="subProduct" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onFocus={getSubProduct}
                  onSelect={Onselect}
                  onSearch={onChange}
                >
                  {subProductData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <p />
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Design Code"
                name="designCode"
                rules={[
                  {
                    required: true,
                    message: "Please select Design Code!",
                  },
                ]}
                style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please select Design Name!",
                  },
                ]}
                label="Design Name"
                name="designName"
                style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Designed By" name="designedBy" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                <Select allowClear showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} onFocus={getDesignedBy}>
                  {designedByData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <p />

          <Row gutter={16}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Description"
                name="description"
                initialValue={description}
                rules={[
                  {
                    required: true,
                    message: "Please enter description",
                  },
                ]}
                style={{ marginBottom: "8px" }}
              >
                <ReactQuill
                  theme="snow"
                  // onChange={handleChange}
                  // value={description}
                  modules={{
                    toolbar: [
                      [{ font: [false, "serif", "monospace"] }, { header: [1, 2, 3, 4, 5, 6, false] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                      [{ align: [] }],
                      ["code", "background"],
                      ["code-block", "direction"],
                      ["link", "image", "video"],
                      ["clean"],
                    ],
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <p />
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Sketch Reference" name="sketchReference" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                <Select onFocus={getSkecthRef}>
                  {sketchData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Design Image" name="designImage" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                <Upload
                  action="https://sapp.mycw.in/image-manager/uploadImage"
                  listType="picture"
                  headers={{ APIKey: "AUa4koVlpsgR7PZwPVhRdTfUvYsWcjkg" }}
                  name="image"
                  // onChange={imageUploadStatusChange}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8} />
          </Row>
        </Form>
      </Card>
      {formVisible ? (
        <Card>
          <Form layout="vertical" form={headerform}>
            <Row gutter={16}>
              {formData.map((element) => {
                const InputType = component[element.type];
                let dataValue = element.data;
                return (
                  <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                    <Form.Item label={element.name} name={element.name}>
                      {element.type === "LI" ? (
                        <InputType mode="tags">
                          {dataValue?.map((item, index) => (
                            <Option key={element.mProductAttributeId} value={item}>
                              {item}
                            </Option>
                          ))}
                        </InputType>
                      ) : (
                        <>
                          {/* {console.log("value=============>", InputType)} */}
                          <InputType value={InputType === DatePicker ? null : elementValue} onChange={getINputValue} key={element.mProductAttributeId} />
                        </>
                      )}
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
          </Form>
        </Card>
      ) : (
        ""
      )}
    </Spin>
  );
};
export default NewProductDesign;
