import React, { useState, useEffect } from "react";
import { getFilterDropdownData } from "../../services/generic";
import useDebounce from "../../lib/hooks/useDebounce";
import { Select } from "antd";

const { Option } = Select;

const FilterSelector = (props) => {
  const { selectedKeys, setSelectedKeys, id, tab, getTabDataResponse } = props;
  const [searchData, setSearchData] = useState([]);
  const [json, setJson] = useState();

  useEffect(() => {
    let sessionValues = {};
    tab.fields.map((field) => {
      if (field.issession === "Y") {
        sessionValues[field.column_name] = getTabDataResponse[0][field.ad_field_id];
      }
      return null;
    });

    if (Object.keys(sessionValues).length !== 0) {
      const stringifiedSession = JSON.stringify(sessionValues);
      const updatedSession = stringifiedSession.replace(/\\"/g, '\\"');
      const stringRequest = JSON.stringify(updatedSession);
      setJson(stringRequest);
    } else {
      setJson(null);
    }
  }, [searchData]); 

  const getColumnData = async (id) => {
    const dropDownData = await getFilterDropdownData(id, null, json);
    const data = JSON.parse(dropDownData);
    if (data !== null) {
      setSearchData([...data.searchData])
    }                                                                                                                                                                                       
  };

  const [searchKey, setSearchkey] = useState();
  const [searchId, setSearchId] = useState();
  const debouncedSearchKey = useDebounce(searchKey, 350);

  useEffect(async () => {
    if (debouncedSearchKey) {
      const dropDownData = await getFilterDropdownData(searchId, debouncedSearchKey, json);
      const data = JSON.parse(dropDownData);
      if (data !== null) {
        setSearchData([...data.searchData])
      } 
    }
  }, [debouncedSearchKey]);

  const handleSearch = (id) => (value) => {
    setSearchId(id)
    setSearchkey(value);
  };

  const dropDownOptions = searchData.map((d) => <Option key={d.RecordID}>{d.Name}</Option>);

  return (
    <Select style={{ marginBottom: 8, width: 200 }} mode="multiple" showSearch allowClear value={selectedKeys[0]} defaultActiveFirstOption={false} filterOption={false} onSearch={handleSearch(id)} onFocus={() => getColumnData(id)} notFoundContent={null} onChange={(newValue) => setSelectedKeys(newValue ? [newValue] : [])}>
      {dropDownOptions}
    </Select>
  );
};

export default FilterSelector;